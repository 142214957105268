import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import {
  getCourseCohortCompletionsOnce,
  fetchUserData,
  getCourseCurrentCohort,
} from "api/db";
import Spinner from "react-spinkit";
import styled from "styled-components";
import { Container, Verify } from "components/Courses/CertificateStyles";

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const Certificate = () => {
  const [data, setData] = useState(); // userData
  const [cohort, setCohort] = useState(); // eventually add date data to this!
  const [milestones, setMilestones] = useState([]); // milestones & completion data
  const courseName = "web-development";
  const [cohortData, setCohortData] = useState([]); //date, cohort, name
  const color = "red";
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let user = urlParams.get("user");
    fetchUserData(user).once("value", snapshot => {
      let userData = Object.values(snapshot.val())[0];
      setData(userData);
      let uid = Object.keys(snapshot.val())[0];
      if (
        userData && 
        userData.courses && 
        userData.courses.find((s) => s.includes("wd"))
      ) {
        let cohortNum = 0;
        let wd = userData.courses.filter((s) => s.includes("wd"));
        let wdcurrent = wd[wd.length - 1];
        cohortNum = parseInt(wdcurrent.slice(wdcurrent.length - 1));
        getCourseCurrentCohort(courseName, cohortNum).then((snapshot) => {
          setCohortData(snapshot.val());
          setCohort(snapshot.val().cohort);
        });

        getCourseCohortCompletionsOnce(courseName, cohortNum, uid).then(
          snapshot => {
            let data = snapshot.val();
            data = Object.values(data).filter(
              a =>
                a.milestone == "project-1" ||
                a.milestone == "project-2" ||
                a.milestone == "project-3"
            );
            setMilestones(data);
          }
        );
        setLoading(false);
      } else {
        navigate("/login");
      }
    });
  }, []);

  // make this dynamic

  return (
    <>
    {loading ? (
        <SpinnerContainer>
          <Spinner name="ball-scale-multiple" color="#438cee" />
        </SpinnerContainer>
      ) : (
        <>
          {milestones.length == 3 ? (
            <>
              {/* <CertificateParticles /> */}
              <Container className="text-gray-900">
                <h3 className="text-xl mb-2 tracking-tight">
                  {cohortData ? cohortData.date : "no date"}
                </h3>
                <div
                  className={`py-1 bg-${color}-600 hover:bg-${color}-500 items-center text-${color}-100 leading-none rounded-full flex inline-flex mb-2 cursor-default`}
                  role="alert"
                >
                  <span
                    className={`flex rounded-full uppercase px-1 py-1 text-xs font-bold mr-2 ml-2 print:ml-0 print:px-0 print:text-black`}
                  >
                    {cohortData.name} {cohortData.cohort}
                  </span>
                </div>
                <h1 className="tracking-widest uppercase font-bold text-xl">
                  THIS CERTIFICATE VERIFIES THAT
                </h1>
                <h1 className="tracking-tight text-4xl font-bold">
                  {data.username}
                </h1>
                <p className="text-xl max-w-lg">
                  {`has learned the fundamentals of HTML, Javascript, and CSS, and has built 3 projects in web development.`}
                </p>
                <h1 className="tracking-widest uppercase font-bold text-base mt-4">
                  PROJECTS
                </h1>
                {milestones.length == 3 ? (
                  <>
                    {milestones.map((milestone, i) => (
                      <div
                        key={milestone.milestone}
                        className="mb-2 max-w-sm text-gray-900 py-2"
                      >
                        <h3 className="tracking-tight text-xl font-bold">
                          {milestones[i].title}
                        </h3>
                        <p className="text-base">
                          {milestones[i].description &&
                            `${milestones[i].description}`}
                        </p>
                        {milestones[i].github ? (
                          <>
                            <p className="link" style={{ marginBottom: "4px" }}>
                              Source:{" "}
                              <a href={`${milestones[i].github}`}>
                                {milestones[i].github}
                              </a>
                            </p>
                            <a
                              target="_blank"
                              className="text-yellow-700 hover:opacity-75 inline-block z-10 print:hidden"
                              href={milestones[i].github}
                            >
                              Source
                            </a>
                          </>
                        ) : null}
                        {milestones[0].demo ? (
                          <>
                            <p className="link" style={{ marginBottom: "4px" }}>
                              Demo:{" "}
                              <a href={`${milestones[i].demo}`}>
                                {milestones[i].demo}
                              </a>
                            </p>
                            <a
                              target="_blank"
                              className="text-blue-700 ml-2 hover:opacity-75 inline-block z-10 print:hidden"
                              href={milestones[i].demo}
                            >
                              Demo
                            </a>
                          </>
                        ) : null}
                      </div>
                    ))}
                  </>
                ) : null}

                <img src="/img/ribbon.png" id="ribbon" />

                <Verify>
                  Verify at https://enlight.nyc/courses/web-development/certificate?user=
                  {data && data.id} <br />
                  Enlight has confirmed their participation in this course.
                </Verify>
              </Container>
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default Certificate;
